import React, { useRef, useState } from 'react';
import HeroHeader from '../components/HeroHeader/HeroHeader';
import Layout from '../components/Layout/Layout';
import componentFactory from '../utils/componentFactory';
import ReactMarkdown from 'react-markdown';
import './formation-template.scss';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from 'gatsby';
import LinkRenderer from '../utils/linkTransform';
import Popup from '../components/Popup/Popup';
import Info from '../assets/images/info.png';
import Seo from '../components/Seo';
const slugify = require('slugify');

const FormationTemplate = ({ pageContext }) => {
    const modal = useRef(null);
    const [open] = React.useState(false);
    const formationModal = useRef([]);
    const blocs = useRef([]);
    const breakpoints = useBreakpoint();
    const [showAllText, setShowAllText] = useState(false);

    const handleClickToOpen = () => {
        modal.current.showModal();
    };
    const handleClickToClose = () => {
        modal.current.close();
    };
    const handleClickToOpenFormationModal = () => {
        formationModal.current.showModal();
    };
    const handleClickToCloseFormationModal = () => {
        formationModal.current.close();
    };

    const {
        data: {
            attributes: { Contenu },
        },
    } = pageContext;
    const {
        data: { attributes },
    } = pageContext;

    const components = Contenu.map((element) =>
        componentFactory(element, pageContext),
    );

    function removeAttributeOpen() {
        blocs.current.forEach((targetDetail) => {
            targetDetail.addEventListener('click', () => {
                // Close all the details that are not targetDetail.
                blocs.current.forEach((bloc) => {
                    if (bloc !== targetDetail) {
                        bloc.removeAttribute('open');
                    }
                });
            });
        });
    }

    const shortDesc =
        attributes.description.slice(0, !showAllText && 300) + '...';

    return (
        <Layout>
            <Seo
                description={pageContext.data?.attributes.metaDescription}
                title={pageContext.data?.attributes.titre}
                lang="fr"
            ></Seo>
            <dialog
                className="FormationTemplate__PopUp"
                open={open}
                ref={modal}
            >
                <Popup
                    handleClickToClose={handleClickToClose}
                    modal={modal}
                    formationTitle={attributes.titre}
                    formationSurMesure={attributes.formationSurMesure}
                />
            </dialog>
            <HeroHeader element={attributes} />
            <div className="FormationTemplate">
                <section>
                    <span className="FormationTemplate__fil-ariane">
                        <Link to="/">Accueil ></Link>
                        <Link to="/nos-formations"> Nos formations ></Link>
                        <strong> {attributes.titre} </strong>
                    </span>
                    <h2 className="FormationTemplate__petit-titre">
                        Formation {attributes.categorie.data?.attributes.nom}
                    </h2>
                    {breakpoints.mobile ? (
                        <>
                            {attributes.description.length > 300 &&
                            showAllText === false ? (
                                <div className="FormationTemplate__contenu">
                                    <ReactMarkdown
                                        components={{ a: LinkRenderer }}
                                    >
                                        {shortDesc}
                                    </ReactMarkdown>
                                </div>
                            ) : (
                                <div className="FormationTemplate__contenu">
                                    <ReactMarkdown
                                        components={{ a: LinkRenderer }}
                                    >
                                        {attributes.description}
                                    </ReactMarkdown>
                                </div>
                            )}

                            <div
                                className="FormationTemplate__show-more-button"
                                style={showAllText ? { display: 'none' } : {}}
                            >
                                <button onClick={() => setShowAllText(true)}>
                                    En savoir plus
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="FormationTemplate__contenu">
                            <ReactMarkdown components={{ a: LinkRenderer }}>
                                {attributes.description}
                            </ReactMarkdown>
                        </div>
                    )}

                    {attributes.blocDepliable.map((item, i) => {
                        return (
                            <details
                                key={item.id}
                                onClick={removeAttributeOpen}
                                onKeyDown={removeAttributeOpen}
                                ref={(el) => (blocs.current[i] = el)}
                            >
                                <summary>
                                    <span>{item.titre}</span>
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <title>
                                            afficher plus d'informations
                                        </title>
                                        <line
                                            className="svggMoins"
                                            x1="19.75"
                                            y1="8.75"
                                            x2="19.75"
                                            y2="30.25"
                                            stroke="black"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                        />
                                        <line
                                            x1="30.25"
                                            y1="19.75"
                                            x2="8.75"
                                            y2="19.75"
                                            stroke="black"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                        />
                                    </svg>
                                </summary>
                                <p>
                                    <ReactMarkdown
                                        components={{ a: LinkRenderer }}
                                    >
                                        {item.description}
                                    </ReactMarkdown>
                                </p>
                            </details>
                        );
                    })}
                </section>
                {breakpoints.responsiveMenu ? (
                    <>
                        <div
                            className="FormationTemplate__filtering-box"
                            onClick={handleClickToOpenFormationModal}
                        >
                            <img
                                src={Info}
                                alt="information sur la formation"
                            />
                            <div className="FormationTemplate__filtering-box--search">
                                <h2>Prix de la formation & détails</h2>
                                <p>
                                    Ressources de la formation, formule
                                    intra-entreprise...
                                </p>
                            </div>
                        </div>
                        <dialog
                            className="FormationTemplate__filters-popup"
                            id="FiltragePop"
                            open={open}
                            ref={formationModal}
                        >
                            <div className="FormationTemplate__Modal-formation">
                                <div
                                    className="FormationTemplate__cross"
                                    onClick={handleClickToCloseFormationModal}
                                ></div>
                                <span className="FormationTemplate__price">
                                    {attributes.prix
                                        ? attributes.prix + '€ HT'
                                        : 'Prix sur devis'}
                                </span>
                                <div className="FormationTemplate__resume">
                                    <ReactMarkdown
                                        components={{ a: LinkRenderer }}
                                    >
                                        {attributes.resume}
                                    </ReactMarkdown>
                                </div>
                                {attributes.formationSurMesure ? (
                                    <button
                                        onClick={handleClickToOpen}
                                        className="FormationTemplate__purple-button purple-button--arrow"
                                    >
                                        Demander un devis
                                    </button>
                                ) : (
                                    <button
                                        onClick={handleClickToOpen}
                                        className="FormationTemplate__purple-button purple-button--arrow"
                                    >
                                        Suivre cette formation
                                    </button>
                                )}
                                <div className="FormationTemplate__desc-sur-mesure">
                                    <ReactMarkdown
                                        components={{ a: LinkRenderer }}
                                    >
                                        {
                                            attributes.descriptionFormationSurMesure
                                        }
                                    </ReactMarkdown>
                                </div>
                                {!attributes.formationSurMesure && (
                                    <Link
                                        to={`/se-former-sur-mesure/${slugify(
                                            attributes.categorie.data?.attributes.nom.toLowerCase(),
                                        )}`}
                                    >
                                        <button className="FormationTemplate__white-purple-button white-purple-button--arrow">
                                            Formation sur mesure
                                        </button>
                                    </Link>
                                )}
                            </div>
                        </dialog>
                    </>
                ) : (
                    <aside>
                        <span className="FormationTemplate__price">
                            {attributes.prix
                                ? attributes.prix + '€ HT'
                                : 'Prix sur devis'}
                        </span>
                        <div className="FormationTemplate__resume">
                            <ReactMarkdown components={{ a: LinkRenderer }}>
                                {attributes.resume}
                            </ReactMarkdown>
                        </div>
                        {attributes.formationSurMesure ? (
                            <button
                                onClick={handleClickToOpen}
                                className="FormationTemplate__purple-button purple-button--arrow"
                            >
                                Demander un devis
                            </button>
                        ) : (
                            <button
                                onClick={handleClickToOpen}
                                className="FormationTemplate__purple-button purple-button--arrow"
                            >
                                Suivre cette formation
                            </button>
                        )}
                        <div className="FormationTemplate__desc-sur-mesure">
                            <ReactMarkdown components={{ a: LinkRenderer }}>
                                {attributes.descriptionFormationSurMesure}
                            </ReactMarkdown>
                        </div>
                        {!attributes.formationSurMesure && (
                            <Link
                                to={`/se-former-sur-mesure/${slugify(
                                    attributes.categorie.data?.attributes.nom.toLowerCase(),
                                )}`}
                            >
                                <button className="FormationTemplate__white-purple-button white-purple-button--arrow">
                                    Formation sur mesure
                                </button>
                            </Link>
                        )}
                    </aside>
                )}
            </div>
            {components}
        </Layout>
    );
};

export default FormationTemplate;
